import { useState, useEffect } from "react";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { buySubscription, fetchSubscriptionProps } from "../lib/api/payments";
import { gtmOnSubscription } from "../lib/api/gtm";
import { isPhoneValid } from "../lib/validation";
import { useCity } from "../lib/context/citycontext";

import FormControlFeedback from "./controls/formcontrolfeedback";
import PrivacyNote from "./privacynote";
import PhoneNumberInput from "./controls/phonenumberinput";
import { getNameLabel } from "../lib/coreutils";

export default function SubscriptionModal({ tariff, subscriptionDescription, onHide }) {
    const [subscriptionProps, setSubscriptionProps] = useState(null);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [canUseBalance, setCanUseBalance] = useState(false);
    const [useBalance, setUseBalance] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState({});
    const { city } = useCity();

    useEffect(() => {
        async function loadSubscriptionProps() {
            const props = await fetchSubscriptionProps(tariff?.id, subscriptionDescription?.id);
            if (props && props.player) {
                setName(props.player.name);
                setPhone(props.player.phone);
            }
            if (props && props.can_use_balance) {
                setCanUseBalance(props.can_use_balance);
            }
            if (tariff && tariff.price) {
                setSubscriptionProps({
                    price: tariff.price,
                    numGames: tariff.num_games,
                });
            } else if (subscriptionDescription) {
                setSubscriptionProps({
                    price: subscriptionDescription.price,
                    numGames: subscriptionDescription.num_games,
                });
            } else if (props && props.subscription) {
                setSubscriptionProps({
                    price: props.subscription.price,
                    numGames: props.subscription.num_games,
                });
            }
        }
        loadSubscriptionProps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBuySubscription = async () => {
        setIsProcessing(true);
        const tariffId = tariff ? tariff.id : 0;
        const subscriptionDescriptionId = subscriptionDescription ? subscriptionDescription.id : 0;
        const response = await buySubscription(
            city.slug,
            tariffId,
            subscriptionDescriptionId,
            name,
            phone,
            canUseBalance && useBalance
        );
        const json = await response.json();
        if (response.ok) {
            setTimeout(() => {
                window.location.href = json.redirect;
            }, 100);
            gtmOnSubscription(json);
        } else {
            setErrors(json);
            setIsProcessing(false);
        }
    };

    const toggleUseBalance = () => {
        setUseBalance((prevUseBalance) => !prevUseBalance);
    };

    if (!subscriptionProps || !subscriptionProps.price) {
        return null;
    }

    const modalStyle = isProcessing ? { cursor: "wait" } : {};

    const purchaseDescription = subscriptionDescription ? (
        <span>{subscriptionDescription.name}</span>
    ) : (
        <>
            на <span>{subscriptionProps.numGames}</span> игр
        </>
    );

    return (
        <Modal show centered className="modal--fancy modal--enrollment" onHide={onHide} style={modalStyle}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Покупка абонемента</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errors.subscription && <Alert variant="danger">{errors.subscription[0]}</Alert>}
                <div className="enrollment-modal__player">
                    <Form.Group className="input-wrapper">
                        <Form.Control
                            type="text"
                            size="lg"
                            value={name}
                            placeholder={getNameLabel()}
                            isInvalid={errors.name}
                            required={true}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <FormControlFeedback errors={errors.name} />
                    </Form.Group>
                    <Form.Group className="input-wrapper">
                        <PhoneNumberInput
                            phone={phone}
                            isInvalid={errors.phone}
                            required={true}
                            onChange={setPhone}
                            size="lg"
                        />
                        <FormControlFeedback errors={errors.phone} />
                    </Form.Group>
                </div>
                <div className="enrollment-modal__price">
                    Абонемент {purchaseDescription} за{" "}
                    {useBalance ? (
                        <>
                            <span className="text-nowrap">
                                <del>{subscriptionProps.price}</del>₽
                            </span>{" "}
                            <span className="discount-price text-nowrap">{subscriptionProps.price} бонусов</span>
                        </>
                    ) : (
                        <>
                            <span>{subscriptionProps.price}</span>₽
                        </>
                    )}
                </div>
                {canUseBalance && (
                    <Form.Group className="enrollment-modal__use-balance">
                        <Form.Check
                            type="checkbox"
                            checked={useBalance}
                            id="use-balance"
                            onChange={toggleUseBalance}
                            inline
                            label="Использовать бонусы"
                            isInvalid={errors.use_balance}
                            className={errors.use_balance ? "is-invalid" : null}
                        />
                        <FormControlFeedback errors={errors.use_balance} />
                    </Form.Group>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={onBuySubscription}
                    disabled={isProcessing || name == "" || !isPhoneValid(phone)}
                >
                    Купить
                </Button>

                <PrivacyNote />
            </Modal.Footer>
        </Modal>
    );
}
