import { callAPIGet, callAPIJsonPost } from "../api";

export async function fetchSubscriptionProps(tariffId, subscriptionDescriptionId) {
    const data = {};
    if (tariffId) {
        data.tariff = tariffId;
    }
    if (subscriptionDescriptionId) {
        data.subscription_description = subscriptionDescriptionId;
    }
    const response = await callAPIGet("/api/v1/subscription/props/", data);
    return response.json();
}

export async function buySubscription(city, tariff, subscription_description, name, phone, use_balance) {
    const data = { tariff, subscription_description, name, phone };
    if (use_balance) {
        data.use_balance = true;
    }
    return callAPIJsonPost(`/api/v1/subscription/?city=${city}`, data);
}

export async function fetchActiveSubscriptions(player_id) {
    const response = await callAPIGet("/api/v1/subscription/active/", { player_id });
    return response.json();
}

export async function fetchArchivedSubscriptions(player_id) {
    const response = await callAPIGet("/api/v1/subscription/archived/", { player_id });
    return response.json();
}

export async function fetchSubscriptionGames(id, player_id) {
    const response = await callAPIGet(`/api/v1/subscription/${id}/subscription-games/`, { player_id });
    return response.json();
}

export async function fetchSubscriptionDescriptions(cookies) {
    const response = await callAPIGet("/api/v1/subscription/descriptions/", {}, { cookies });
    return response.json();
}

export async function fetchParticipationPaymentUrl(participationCode) {
    return callAPIGet(`/api/v1/participationpurchase/${participationCode}/payment-url/`, {});
}

export async function fetchPaymentStatusPageProps(paymentId) {
    return callAPIGet(`/api/v1/pages/paymentstatus/${paymentId}/`);
}

export async function fetchPaymentStatus(paymentId) {
    return callAPIGet(`/api/v1/payment/${paymentId}/status/`);
}

export async function sendPaymentNotification(paymentId) {
    return callAPIGet(`/api/v1/payment/${paymentId}/notification-received/`);
}

export async function makePayment(paymentId) {
    return callAPIJsonPost(`/api/v1/payment/${paymentId}/pay/`, {});
}
